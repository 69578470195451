<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container>
    <v-row
      justify="center"
    >
      <v-col
        cols="12"
        lg="7"
        md="9"
      >
        <v-card
          class="mx-auto mt-10"
          max-width="560"
        >
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-row v-if="formLabel">
                  <v-col cols="12">
                    <h4>{{ formLabel }}</h4>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-otp-input
                      ref="otp"
                      v-model="form.code"
                      class="ltr"
                      hide-spin-buttons
                      reverse
                      type="number"
                      length="4"
                    />
                  </v-col>
                </v-row>
                <v-row justify="space-around">
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <app-btn
                      :block="AppIsSmall"
                      :disabled="!form.code||form.code.length!==4"
                      :loading="loading"
                      @click="submit"
                    >
                      {{ $t('done') }}
                    </app-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <app-btn
                      :block="AppIsSmall"
                      :disabled="loading"
                      @click="cancelButton"
                    >
                      {{ $t('cancel') }}
                    </app-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p :class="{'text--disabled': timer === 0 }">
                      {{ $t('loginPage.resend_after',{v: getTimer}) }}
                    </p>
                    <app-btn
                      color="primary"
                      text
                      :disabled="!canSendCode || loading"
                      @click="resendOtp"
                    >
                      {{ $t('loginPage.resend_code') }}
                    </app-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'LoginOtp',
  // components: { AuthComponent: () => import('@components/auth/Login') },
  props: {
    mobile: {
      type: [String],
      default: () => null
    },
    country_id: {
      type: [String, Number],
      default: () => null
    },
    token: {
      type: [String],
      default: () => null
    },
    resendTime: {
      type: [String, Number],
      default: () => 2
    },
    label: {
      type: [String],
      default: () => null
    }
  },
  data () {
    return {
      formLabel: null,
      timer: 2 * 60,
      interval: null,
      loading: !1,
      errors: {},
      form: {
        login_id: undefined,
        code: undefined,
        token: undefined
      }
    }
  },
  computed: {
    getTimer () {
      return this.$moment().locale('en').set('m', 0).set('s', 0).add(this.timer, 'seconds').format('mm:ss')
    },
    canSendCode () {
      return this.timer === 0
    },
    validForm () {
      return this.form.code && this.form.code.length === 4
    }
  },
  watch: {
    authUser: {
      deep: !0,
      immediate: !0,
      handler (v) {
        if (v) {
          this.$router.replace({ name: this.APP_ROUTES.user.home })
        }
      }
    }
  },
  mounted () {
    // console.log(this.resendTime)
    if (!this.token || !this.mobile) {
      this.cancelButton()
      return
    }
    this.form = {
      login_id: this.mobile,
      country_id: this.country_id,
      token: this.token
    }
    this.timer = (parseInt(this.resendTime) || 2) * 60
    this.formLabel = this.label
    this.$nextTick(() => {
      this.startTimer()
      this.interval = setInterval(this.startTimer, 1000)
      setTimeout(() => {
        this.$refs.otp.focus()
      }, 300)
    })
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    startTimer () {
      if (this.timer) {
        --this.timer
      }
    },
    cancelButton () {
      this.$router.replace({ name: this.APP_ROUTES.auth.login })
    },
    async resendOtp () {
      if (this.loading) return
      this.errors = {}
      this.loading = !0
      try {
        const { _success, _data, _message } = await this.apiService.auth.login(this.form)
        _message && this.alertSuccess(_message)
        if (_success === !0) {
          this.form.token = _data.factorToken
          this.timer = (parseInt(_data.resend_time) || 2) * 60
          this.formLabel = _message || this.label
        }
      } catch ({ _message, _errors }) {
        _message && this.alertError(_message)
        this.errors = _errors || {}
      } finally {
        this.loading = !1
      }
    },
    async submit () {
      const form = { ...this.form }
      if (this.loading || !this.validForm) return

      if (this.IsMobileApp) {
        form.is_mobile = !0
      }

      this.loading = !0
      this.errors = {}
      try {
        const { data, _data: user, _success } = await this.apiService.auth.otpLogin(form)
        const token = data?.token
        if (token && _success === !0 && user) {
          this.loginUser({ token, user })
        }
      } catch (e) {
        // alert(e)
        const { _message, _errors } = e
        this.errors = _errors || {}
        const message = _message || e.message
        message && this.alertError(message)
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>
